import Storage from "@/util/Storage";
import {delLimitTimeDate, getLimitTimeDate} from "@/Api/ShopAdmin";
import {Toast} from "vant";

// 查询限时购时间
export function getLimitTimeList(body:any = {}) {
    const userId = Storage.GetData_("userId")
    const token = Storage.GetData_("token")
    return Promise.resolve(getLimitTimeDate({userId,token},body).then(res=>{
        return res.data
    }))
}

// 删除限时购时间
export function delLimitTimeList(id:string|number = "") {
    const userId = Storage.GetData_("userId")
    const token = Storage.GetData_("token")
    Toast.loading({ duration:0,message:"删除中~~~" })
    return Promise.resolve(delLimitTimeDate({ userId,token },{ id }).then(res=>{
        Toast.clear()
        return res.data
    }))
}

import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "@/store/index"
import LimitTimeDateStoreType from "@/views/ShopAdmin/LimitTimeDate/Store/indexType";

@Module({
    name:"LimitTimeDate",
    store,
    dynamic: true,
})
export default class LimitTimeDate extends VuexModule implements LimitTimeDateStoreType{
    LimitTimeDateSelect: any = {};
    get getLimitTimeDateSelect(){
        return this.LimitTimeDateSelect
    }
    @Mutation
    SetLimitTimeDateSelect(data: any): void {
        this.LimitTimeDateSelect = data
    }

}

export const LimitTimeDateStore = getModule( LimitTimeDate )
